.sidebar {
  background: rgb(217 230 228);
  color: #000;
  transition: all 0.5s;
}
.maincontent {
  width: 98%;
}
.main-menu-display {
  display: flex;
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: hidden;
}
.main-content .header {
  padding: 10px 20px;
  border-bottom: 1px solid #ccc;
  width: 100%;
  padding-top: 15px;
}
.header .row .new-bi-button {
  display: flex;
  align-items: center;
  padding: 0px 10px;
  height: 36px;
  background-color: white;
  border: 1px solid #eaeaea;
  border-radius: 10px;
}
.header .row .justify-content-end .logout-icon {
  float: right;
  margin-left: 5px;
  padding: 7px;
  width: 35px;
  border-radius: 35px;
  border: 1px solid #ccc;
}

/* #sidebar{
    height:calc(100vh - 100px);
  } */
.container {
  display: flex;
}
.bars {
  display: flex;
  font-size: 25px;
  margin-left: 30px;
}
.top_section {
  display: flex;
  align-items: center;
  padding: 20px 15px;
}
.logo {
  font-size: 24px;
}
.parent-link,
.child-link {
  display: flex !important;
  color: #000;
  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
}
.parent-link:hover {
  color: #000;
  font-weight: 500;
  cursor: pointer;
}
.child-link.active {
  background: #0a5f59;
  color: #fff;
  border-radius: 10px;
  margin-right: 5px;
  margin-left: 5px;
}
.parent-link.active {
  font-weight: 700;
}
.icon,
.link_text {
  font-size: var(--menu-font-size);
  white-space: nowrap;
}
.link_text.active {
  font-weight: 600;
}
.link_text:hover {
  font-weight: 600;
  color: #000;
}
.sidebar-item {
  /* padding: .75em 1em; */
  display: block;
  transition: background-color 0.15s;
  border-radius: 5px;
}
.linkfirstsidebar-item:hover,
.dashboard-item:hover {
  background-color: #d3dedc;
  border-radius: 5px;
}
.linkfirstsidebar-item.active {
  background-color: #ffffff;
  border-radius: 5px;
}
.linkfirstsidebar-item,
.dashboard-item {
  display: flex;
  color: #000;
  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;
  text-decoration: none;
  line-height: normal !important;
}
.firstsidebar-title {
  padding: 10px 15px;
  display: flex;
  gap: 15px;
  cursor: pointer;
  align-items: center;
  line-height: normal !important;
}
.firstsidebar-item.active {
  background-color: #ffffff;
  border-radius: 5px;
}
.firstsidebar-item.active:has(.firstsidebar-title) .firstsidebar-title {
  background-color: #ffffff;
  border-radius: 5px;
}
.firstsidebar-item:hover {
  background-color: #d3dedc;
  border-radius: 5px;
}
.secondsidebar-title {
  padding: 10px 15px;
  display: flex;
  gap: 15px;
}
.container2 {
  -webkit-box-flex: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: auto;
  flex: auto;
  -ms-flex-direction: column;
  flex-direction: column;
}

.hide {
  display: none;
}
.selectbir {
  width: 250px;
  /* z-index: 10; */
}
.selectpr {
  width: 250px;
  font-size: 16px;
}
.main-footer {
  /* margin-top: 5%; */
  /* margin-right: 10%; */
  position: relative;
}

#form-file-upload {
  height: 120px;
  max-width: 100%;
  text-align: center;
  position: relative;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: "Source Sans Pro";
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.popover-fileupload {
  height: 430px;
  width: 420px;
  z-index: 9999;
  position: absolute;
  transform: translate3d(0px, 70px, 0px);
}

.tox-notifications-container {
  display: none;
}

.header-icons {
  cursor: pointer;
  float: right;
  height: 35px;
  margin-left: 5px;
  padding: 2px;
  width: 35px;
  border-radius: 35px;
  border: 1px solid #ccc;
  text-align: center;
}

@media (min-width: 576px) {
  .search-input input {
    width: 160px !important;
  }
}

@media (min-width: 768px) {
  .search-input input {
    width: 200px !important;
  }
}

@media (min-width: 991.98px) {
  .search-input input {
    width: 240px !important;
  }
}

.notification-badge {
  position: relative;
  top: -38px;
  right: -10px;
}

.selectmeeting {
  margin-left: 15px;
  width: 250px;
  z-index: 10;
}

.tooltips {
  position: absolute;
  background-color: #333333e7;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: var(--captions-font-size);
  z-index: 999;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

div:hover .tooltip {
  /* margin-left: 35px; */
  opacity: 1;
  transform: translateX(30px) translateY(-5px);
}
.react-chatbot-kit-chat-container {
  width: 300px !important;
  z-index: 99;
  border: 1px solid lightgrey;
}

.react-chatbot-kit-chat-message-container {
  overflow: hidden;
}

.danger,
.success,
.risk,
.complete,
.hold,
.info,
.default-status,
.Low,
.High,
.Medium,
.Urgent,
.Open,
.Working,
.Pending-Review,
.Overdue,
.Template,
.Completed,
.Cancelled {
  color: #ef2c2c;
  /* width: Fixed (80px); */
  height: 24px;
  padding: 5px 12px 5px 12px;
  border-radius: 32px;
  gap: 8px;
  background-color: #fbd9d9;
  font-size: var(--captions-font-size);
}

.success {
  color: #0a5f59;
  background-color: #d9f6e4;
}
.complete {
  color: #51a5f2;
  background-color: #d8eafb;
}
.hold {
  color: #646464;
  background-color: #deddda;
}
.risk {
  color: #f09642;
  background-color: #f3eae1;
}
.info {
  color: #f09642;
  background-color: #f3eae1;
}

.default-status {
  color: #646464;
  background-color: #deddda;
}

.progress-card {
  width: 100%;
  height: 3px;
}

.progress-card-danger,
.progress-card-success,
.progress-card-ontrack,
.progress-card-complete,
.progress-card-onhold,
.progress-card-info,
.progress-card-default {
  width: 100%;
  height: 3px;
  border-bottom-left-radius: 20px;
}
.progress-card-danger {
  background-color: #f3eae1;
}
.progress-card-ontrack {
  background-color: #d9f6e4;
}
.progress-card-complete {
  background-color: #d8eafb;
}
.progress-card-onhold {
  background-color: #deddda;
}
.progress-card-info {
  background-color: #faf6ea;
}
.progress-card-default {
  background-color: #e6d9fb;
}

.danger-progress,
.success-progress,
.ontrack-progress,
.complete-progress,
.onhold-progress,
.risk-progress,
.info-progress {
  width: 25%;
  border-bottom-left-radius: 20px;
  height: 3px;
  background-color: #ef2c2c;
}
.default-progress {
  width: 5%;
  border-bottom-left-radius: 20px;
  height: 3px;
}
.info-progress {
  background-color: #f8c434;
}

.ontrack-progress {
  background-color: #0a5f59;
}
.complete-progress {
  background-color: #51a5f2;
}
.onhold-progress {
  background-color: #646464;
}
.risk-progress {
  background-color: #f09642;
}

.Low {
  background-color: #4477ce;
  color: white;
}
.Medium {
  background-color: #0dba55;
  color: white;
}
.High {
  background-color: #f8c434;
  color: white;
}
.Urgent {
  background-color: #ef2c2c;
  color: white;
}
.Open,
.Working,
.Pending-Review,
.Overdue,
.Template,
.Completed,
.Cancelled {
  color: white;
}
.Open {
  background-color: #646464;
}
.Working {
  background-color: #0da14b;
}
.Pending-Review {
  background-color: #ebe76c;
}
.Overdue {
  background-color: #f8c434;
}
.Template {
  background-color: #4477ce;
}
.Completed {
  background-color: #17594a;
}
.Cancelled {
  background-color: #ef2c2c;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  padding: 20px;
  max-width: 1000px;
  height: 90%;
  overflow-y: auto;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

.modal-body {
  /* display: flex; */
  padding: 10px;
}

.modal-body-left {
  width: 50%;
  border: 1px solid lightgray;
  background: #ecf4f3;
  /* padding: 10px; */
}

.modal-body-right {
  width: 50%;
  padding-left: 20px;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  flex-wrap: wrap;
}

.meeting-table th,
.meeting-table td {
  padding: 5px !important;
  text-align: center;
}
/* calender css */
.fc-daygrid-day a,
.fc-col-header-cell .fc-scrollgrid-sync-inner a {
  text-decoration: none;
  color: black;
}

.dashboard-tasks-table td,
.dashboard-tasks-table th {
  padding: 8px;
  font-size: var(--sub-heading-font-size);
}
.dashboard-tasks-table td {
  font-size: var(--text-font-size);
}
.notification-menu {
  text-align: left;
  right: 60px;
  background-color: #ecf4f3;
  position: absolute;
  z-index: 9999;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  top: 62px;
  overflow-y: scroll;
  max-height: 520px;
}

.folder-managementicons {
  margin-top: 10px;
  cursor: pointer;
  float: center;
  height: 35px;
  padding: 2px;
  width: 35px;
  /* border-radius: 35px; */
  /* border: 1px solid #ccc; */
  text-align: center;
}
.bordered-table-upload-files {
  font-size: var(--sub-heading-font-size);
  border-collapse: collapse;
  width: 98%;
  margin-left: 10px;
}

.bordered-table-upload-files th,
.bordered-table-upload-files td {
  border: 1px solid lightgray;
  text-align: center;
  padding-bottom: 0px;
  padding: 5px !important;
}
.menu-dashboard-title {
  display: flex;
  padding: 10%;
  padding-right: 30px;
  font-size: var(--menu-heading-font-size);
  align-items: center;
  background-color: #ecf4f3;
}

.main-footer .show-template-button,
.main-footer .back-button-style,
.main-footer .show-edit-button,
.main-footer .next-button-style,
.ecosystem-map-view-report,
.save-button-style {
  font-size: var(--sub-heading-font-size) !important;
  background-color: white !important;
  color: black !important;
  text-align: center;
  border: 1px ridge !important;
  /* padding: 4px 10px !important; */
}
.back-button-style {
  right: "100px";
}
.save-button-style-rhpo {
  cursor: pointer;
  font-size: var(--sub-heading-font-size) !important;
  background-color: var(--button-primary-color) !important;
  padding: 4px 10px !important;
  border: 1px solid var(--button-primary-color) !important;
  position: absolute !important;
  top: 10px !important;
  right: 70px !important;
}
.cancel-button-style-rhpo {
  cursor: pointer;
  font-size: var(--sub-heading-font-size) !important;
  background-color: white !important;
  padding: 4px 10px !important;
  border: thin ridge !important;
  position: absolute !important;
  bottom: 40px !important;
  right: 120px !important;
}
.ecosystem-map-view-report {
  font-size: var(--sub-heading-font-size) !important;
}
.main-footer .next-button-style,
.save-button-style {
  color: white !important;
  background-color: #0a5f59 !important;
  border: 1px solid #0a5f59 !important;
  margin-left: 20px;
  padding: 4px 10px !important;
}
/* .main-footer {
  position: absolute;
  right: 0;
  bottom: 10;
} */

.bidetails-upload-container,
.operating-upload-container {
  width: 50%;
}
.global-serach-field{
  height: 36px;
  width: 300px;
  float: right;
  margin-bottom: 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
}
.global-serach-field:focus{
  outline: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.global-search-result:hover {
  background-color: #F4F5F6;
  border-radius: 5px;
}
/* Responsive for Tab */
@media (min-width: 600px) and (max-width: 1024px) {
  .maincontent {
    width: 98%;
  }
  .bidetails-upload-container {
    width: 98%;
  }
  .operating-upload-container {
    width: 100%;
  }
}
