.goals-table {
  width: 100%;
  padding: 0px 10px 10px 10px;
  font-size: var(--sub-heading-font-size);
}

table {
  width: 100%;
  border-collapse: collapse;
}

.goal-table-data th {
  padding-bottom: 10px;
}

th,
td {
  /* padding: 10px 10px 10px 10px; */
  text-align: center;
  /* border-bottom: 1px solid #ccc; */
}

.goals-table tbody tr {
  background-color: #f8f8f8;
  border-bottom: 2px solid #eeeeee;
}
.narrow-heading {
  text-align: left;
}

.narrow-heading {
  width: 40%;
}

.milestoneContainer {
  box-shadow: 0px 1px 4px 0px #32302c1a;
  padding: 6px 8px 6px 8px;
}

td.default-progress {
  text-align: left;
}

.react-datepicker__input-container input[type="text"] {
  border: none;
  background: transparent;
  margin: 0;
  width: 100px;
}

.react-datepicker-wrapper {
  width: 270px;
}

.sidenav {
  width: 50%;
  height: calc(100vh - 63px);
  position: fixed;
  z-index: 10;
  top: 63px;
  right: 0;
  bottom: 10px;
  background-color: white;
  overflow-x: hidden;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  border: 1px solid lightgray;
}

.sidenav-header {
  position: fixed;
  padding-top: 30px;
  z-index: 9999;
  width: 50%;
  height: 50px;
  background-color: white;
}

.sidenav a {
  /* padding: 8px 8px 8px 32px; */
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn,
.sidenav .sideMenu {
  text-decoration: none;
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
  padding: 0px;
}

.sidenav .closebtn {
  top: 0px;
}

.sidenav .sideMenu {
  top: 4px;
  font-size: 25px;
  right: 65px;
}

.sidenav .fullscreen,
.sidenavFullscreen .fullscreen,
.sideMenu {
  text-decoration: none;
  position: absolute;
  top: 0;
  right: 100px;
  font-size: 25px;
  margin-left: 50px;
  margin-top: 2px;
}

.sideMenu {
  top: 10px;
  right: 60px;
}
.menu {
  position: absolute;
  top: 50px;
  right: 80px;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 100;
}

@media screen and (max-height: 450px) {
  /* .sidenav {
    padding-top: 15px;
  } */

  .sidenav a {
    font-size: 18px;
  }
}

.highlighted-row {
  background-color: rgb(236, 244, 243);
}

.input-container {
  display: inline-block;
  position: relative;
}

.hover-input {
  border: none !important;
  outline: none;
  background-color: transparent;
}

.hover-input:hover,
.hover-input:focus {
  background-color: white;
  border: 1px solid #ccc !important;
}

.step-progress-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  /* margin: 20px 0; */
}

.step {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  z-index: 1;
  transition: background-color 0.3s ease-in-out;
}

.step.active {
  background-color: green;
  /* Changed color here */
  color: white;
}

.step-content {
  text-align: center;
}
.step-percentage {
  font-size: 12px;
  text-align: center;
  margin-left: 10px;
}

.line-indicator-container {
  width: 100%;
  height: 1px;
  background-color: #a7a0a0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  z-index: 0;
  overflow: hidden;
}

.line-indicator {
  height: 100%;
  background-color: green;
  transition: width 0.3s ease-in-out;
}

/* .tox-tinymcefc {
  height: 30% !important;
  margin: 20px;
  z-index: -1;
} */

.css-13cymwt-control,
.form-control {
  border-radius: 10px !important;
}
.product-category,
.product-status {
  width: 400px !important;
  font-weight: 400;
}
.milestone-select {
  width: 100%;
  font-weight: 400;
}
.functiondata {
  display: flex;
  flex-wrap: wrap;
  padding: 2px 12px 4px 0px;
  border-radius: 28px;
  gap: 8px;
}

.function-button:nth-child(3n + 1) {
  background-color: #8746f1; /* Every 1st button */
}

.function-button:nth-child(3n + 2) {
  background-color: #51a5f2; /* Every 2nd button */
}

.function-button:nth-child(3n) {
  background-color: #df58c9; /* Every 3rd button */
}

.goal-table-data tbody td {
  padding: 0px !important;
}

.task-row {
  padding-left: 10px !important;
}
.workboard-filter {
  display: flex;
  align-items: center;
  font-size: var(--sub-heading-font-size);
  position: absolute;
  top: 82px;
  right: 230px;
  z-index: 1;
}
.workboard-filter label {
  font-weight: 600;
  margin-left: 10px;
}

.workboard-filter .select-filter {
  width: 200px !important;
  font-weight: 400;
  margin-left: 20px;
  font-size: 15px;
}
.workboard-text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 500px;
  text-align: left;
}
.workboard-chevron-down {
  margin-right: 20px;
  
}
.workboard-chevron-down button {
  border: none;
  background-color: transparent;
}
.workboard-chevron-down button:hover {
  border: none;
  background-color: #f8f8f8;
  transition: transform 0.5s ease;
  border-radius: 5px;
}

.workboard-actions-container,
.workboard-actions-nested-container {
  cursor: pointer;
  position: absolute;
  top: 155px;
  left: 100px;
  width: fit-content;
  height: fit-content;
  background-color: white;
  border: 1px solid rgba(162, 162, 162, 0.145);
  border-radius: 5px;
  padding: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 5px;
}
.workboard-actions-container:hover {
  background-color: #f8f8f8;
}

.workboard-actions-nested-container {
  top: 155px;
  left: 240px;
  padding: 5px 0px;
}
.workboard-action-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px 10px;
}
.workboard-action-item label,.workboard-action-item img{
  cursor: pointer;
}
.workboard-action-item:hover {
  background-color: #f8f8f8;
}
