.Main-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
  margin-top: 0px;
  padding-top: 0px;
}
.Main-header h4 {
  width: 75%;
}
.share-button {
  display: flex;
  align-items: center;
  border-radius: 20px;
  background-color: transparent;
  border: none;
  height: 36px;
  margin-right: 10px;
}
.close-button {
  display: flex;
  align-items: center;
  /* color: red; */
  padding: 0px 5px;
  margin-right: 0px;
  /* border: 1px solid red; */
  /* border-radius: 20px; */
  background-color: transparent;
  border: none;
  /* height: 32px; */
}
.meeting-button,
.notes-button {
  display: flex;
  align-items: center;
  padding: 10px;
  margin-right: 10px;
  border-radius: 20px;
  background-color: transparent;
  border: 1px solid lightgray;
  height: 36px;
}

.Main-header .xl-lead {
  width: 16%;
  padding: 6px 12px 6px 12px;
  background-color: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  margin-left: 20px;
  display: flex;
  justify-content: space-evenly;
  font-size: var(--text-font-size);
  text-wrap: nowrap;
}
.line {
  content: "";
  width: 1%;
  height: 22px;
  margin-left: 10px;
  background-color: #eaeaea;
}
.Primary-container,
.secondary-container {
  width: 100%;
  height: 296px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  font-size: var(--sub-heading-font-size);
}

.Primary-container .column-one,
.secondary-container .column-two {
  width: 41%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.Primary-container .column-one .sub-column-one,
.Primary-container .column-one .sub-column-two,
.secondary-container .sub-column-one,
.secondary-container .sub-column-two {
  width: 100%;
  word-wrap: break-word;
  overflow: hidden;
  height: 136px;
  border-radius: 4px;
  border: 1px solid #eaeaea;
}
.Primary-container .column-two {
  word-wrap: break-word;
  overflow: hidden;
  height: 296px;
  width: 41%;
  border-radius: 4px;
  border: 1px solid #eaeaea;
}
.Primary-container .column-three {
  border-radius: 4px;
  width: 15%;
  height: 296px;
}
.Primary-container .column-three .container-head {
  border: 1px solid #eaeaea;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: none;
}
.Primary-container .column-three .sub-column-one {
  height: 205px;
  border: 1px solid #eaeaea;
  border-top: none;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.Primary-container .column-three .sub-column-two {
  height: 10px;
}
.Primary-container .column-three .sub-column-three {
  height: 66px;
  display: flex;
  flex-direction: column;
}
.Primary-container .column-three .sub-column-one .sub-title {
  font-size: var(--captions-font-size);
  padding-top: 15px;
}
.Primary-container .column-three .sub-column-three .sub-title {
  font-size: 11px;
  padding-left: 10px;
}
.sub-column-one .data {
  display: flex;
  justify-content: space-between;
}
.secondary-container .column-one {
  width: 41%;
  border-radius: 4px;
  border: 1px solid #eaeaea;
}
.secondary-container .column-two {
  width: 57.5%;
  height: 296px;
  word-wrap: break-word;
  overflow: hidden;
}
.third-container {
  word-wrap: break-word;
  overflow: hidden;
  width: 100%;
  height: 145px;
  border-radius: 4px;
  border: 1px solid #eaeaea;
  font-size: var(--sub-heading-font-size);
  font-weight: var(--heading-font-weight);
}
.container-head {
  width: 100%;
  height: 40px;
  background-color: #f6f8fa;
}
.container-head th {
  font-weight: 400;
  opacity: 0.5;
}
.title {
  padding-left: 5px;
  font-weight: 600;
  padding: 5px;
  display: flex;
  font-size: var(--sub-heading-font-size);
  font-weight: var(--heading-font-weight);
}
.title button {
  display: flex;
  align-items: center;
  padding: 5px 10px 5px 10px;
  background-color: transparent;
  border: 1px solid #eaeaea;
  border-radius: 6px;
  background-color: white;
  height: 22px;
  margin-left: 10px;
}

table tbody td {
  padding-bottom: 10px;
}


.Primary-container .sub-column-three .data {
  font-size: var(--text-font-size);
  width: 50%;
}

.sidenav {
  height: calc(100vh - 63px);
  width: 50%;
  box-shadow: -5px 0px 20px -15px  rgba(0, 0, 0, 0.3);
}

.select-execution-owner {
  width: 100%;
  border: none;
}
.column-one table td,
.column-two table td {
  font-size: var(--text-font-size);
}

/* progress expand */
.progress-expand-container {
  width: 98%;
  height: 100vh;
  position: fixed;
  z-index: 99;
  top: 60px;
  left: 50px;
  bottom: 10px;
  background-color: white;
  overflow-x: hidden;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  border: 1px solid lightgray;
  font-size: var(--sub-heading-font-size);
}

.progress-expand-container .closebtn {
  font-size: 36px;
  text-decoration: none;
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
  padding: 0px;
  color: black;
}
.meeting-popover {
  padding: 20px;
  min-width: 1200px;
  height: 85%;
  overflow-y: auto;
}
@media (min-width: 600px) and (max-width: 1024px) {
  .sidenav {
    width: calc(100% - 50px);
  }
  .meeting-popover {
    padding: 20px;
    min-width: 800px;
    height: 85%;
    overflow-y: auto;
  }
}
